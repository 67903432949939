<template>
  <div>
    <div v-if="items.type=='txt'">
      <van-field v-model="ruleForm[items.name]" :maxlength="items.length" placeholder="请输入"
                 clearable :rules="items.required?[{required:true,message:'必填'}]:[{}]" autosize type="textarea" :disabled="items.editdis"></van-field>
    </div>
    <div v-else-if="items.type=='phone'">
      <van-field type="tel" v-model.number="ruleForm[items.name]" maxlength="11" :disabled="items.editdis"
                 placeholder="请输入电话号码" :rules="items.required?[{ required: true, message: '必填' },{pattern,message: '电话号码输入有误'}]:[{}]" clearable></van-field>
    </div>
    <div v-else-if="items.type == 'link'">
      <van-field :maxlength="items.length" placeholder="请输入" disabled
                 clearable :rules="items.required?[{required:true,message:'必填'}]:[{}]">
        <template #input>
          <a :href="ruleForm[items.name]" target="_blank">{{ruleForm[items.name]}}</a>
        </template>
      </van-field>
    </div>
    <div v-else>
      <van-field v-model="ruleForm[items.name]" :maxlength="items.length" placeholder="请输入" :disabled="items.editdis"
                 clearable :rules="items.required?[{required:true,message:'必填'}]:[{}]"></van-field>
    </div>
  </div>
</template>

<script>
export default {
  name: "textElemtent",
  props: ['items','ruleForm'],
  data(){
    return{
      pattern:/^[1][3,4,5,7,8][0-9]{9}$/,
    }
  },
  methods: {},
}
</script>

<style scoped>

</style>