<template>
  <div>
    <div v-if="items.type=='number'">
      <van-field v-if="items.required == true" type="digit" v-model="ruleForm[items.name]" maxlength="10"
                 placeholder="请输入整数" :rules="[{required:true,message:'必填'}]" clearable></van-field>
      <van-field v-else type="digit" v-model="ruleForm[items.name]" maxlength="10" placeholder="请输入整数" clearable></van-field>
    </div>
  </div>
</template>

<script>
export default {
  name: "numberElement",
  props: ['items','ruleForm'],
}
</script>

<style scoped>

</style>