<template>
  <div>

  </div>
</template>

<script>
import showList from "@/components/showList";
export default {
  name: "test",
  components: {
    showList,
  },
  mounted() {
    let num = -5.4;
    let date = new Date();
    let val = Math.round(Math.random()*1000);
    console.log("val",date.getMinutes());
    //  /^.$/  [1-3][a-z][A-Z]   /d /w   /s  {num}
  },
  methods: {
  }
}
</script>

<style scoped>

</style>