<template>
  <div>
    <div>
      <text-elemtent :items="items" :ruleForm="ruleForm"></text-elemtent>
      <date-element :items="items" :ruleForm="ruleForm"></date-element>
      <time-element :items="items" :ruleForm="ruleForm"></time-element>
      <datetime-element :items="items" :ruleForm="ruleForm"></datetime-element>
      <number-element :items="items" :ruleForm="ruleForm"></number-element>
      <decimal-element :items="items" :ruleForm="ruleForm"></decimal-element>
      <phone-element :items="items" :ruleForm="ruleForm"></phone-element>
      <select-element :items="items" :ruleForm="ruleForm"></select-element>
      <mutiselect-element :items="items" :ruleForm="ruleForm"></mutiselect-element>
      <img-file :items="items" :ruleForm="ruleForm"></img-file>
      <input v-model.number="val" @change="getval">
    </div>
  </div>
</template>

<script>
import textElemtent from "@/views/components/details/textElemtent";
import dateElement from "@/views/components/details/dateElement";
import timeElement from "@/views/components/details/timeElement";
import datetimeElement from "@/views/components/details/datetimeElement";
import imgFile from "@/views/components/details/imgFile";
import numberElement from "@/views/components/details/numberElement";
import DecimalElement from "@/views/components/details/decimalElement";
import PhoneElement from "@/views/components/details/phoneElement";
import SelectElement from "@/views/components/details/selectElement";
import MutiselectElement from "@/views/components/details/mutiselectElement";

export default {
  name: "showList",
  props: ['items','ruleForm'],
  components: {
    MutiselectElement,
    SelectElement,
    PhoneElement,
    DecimalElement,
    textElemtent,
    dateElement,
    timeElement,
    datetimeElement,
    imgFile,
    numberElement,
  },
  data(){
    return{
      val: "",
    }
  },
  methods: {
    getval(){
      console.log("val",this.val);
      console.log("type",typeof this.val);
    }
  }
}
</script>

<style scoped>

</style>