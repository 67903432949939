<template>
  <div>
    <div v-if="items.type=='phone'">
      <van-field v-if="items.required == true" type="tel" v-model.number="ruleForm[items.name]" maxlength="11"
                 placeholder="请输入电话号码" :rules="[{ required: true, message: '必填' },{pattern,message: '电话号码输入有误'}]" clearable></van-field>
      <van-field v-else type="tel" v-model.number="ruleForm[items.name]" maxlength="11" placeholder="请输入电话号码"
                 clearable :rules="[{ pattern,message: '电话号码输入有误'}]"></van-field>
    </div>
  </div>
</template>

<script>
export default {
  name: "phoneElement",
  props: ['items','ruleForm'],
}
</script>

<style scoped>

</style>