<template>
  <dvi>
    <div v-if="items.type=='decimal'">
      <van-field v-if="items.required == true" type="number" v-model="ruleForm[items.name]" maxlength="10"
                 placeholder="请输入一个数（可小数）" :rules="[{required:true,message:'必填'}]" clearable></van-field>
      <van-field v-else type="number" v-model="ruleForm[items.name]" maxlength="10" placeholder="请输入一个数（可小数）" clearable></van-field>
    </div>
  </dvi>
</template>

<script>
export default {
  name: "decimalElement",
  props: ['items','ruleForm'],
}
</script>

<style scoped>

</style>